import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ระบบเกม"
    }}>{`ระบบเกม`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "การตกปลา"
    }}>{`การตกปลา`}</h3>
    <p>{`คุณสามารถตกปลาได้ที่บ่อน้ำในฟาร์มของคุณ สามารถซื้อเบ็ดตกปลาได้ที่ร้านค้าเบนจามิน`}</p>
    <p><img alt="mat" src={require("./public/images/fish-1.png")} /></p>
    <p>{`การตกปลา สามารถทำได้ง่าย ๆ เพียง สังเกตุเงาของปลาที่เข้ามากินเบ็ด แล้วกดดึงเบ็ดขึ้นให้ทันเวลาพอดี สามารถตกปลาได้ 5 ครั้งต่อวัน ( รีเซ็ตทุกวันตอน 4 . 00 น. )`}</p>
    <p><img alt="mat" src={require("./public/images/fish-2.png")} /></p>
    <p><img alt="mat" src={require("./public/images/fish-3.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      